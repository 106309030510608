import React from 'react';
import Nav from "../Nav";

const Header = () => {
  return (
    <header className="header">
      <div className="inner-wrapper">
        <h1>Harmony House</h1>
        <Nav />
      </div>
    </header>
  );
};

export default Header;
