import React, {useState} from "react";
import {Link} from "react-router-dom";
import Hero from "../components/Hero";
import hero from "../img/travel.webp";
import locationData from "../data/travel.json";

const Location = (data) => {
  const {hero, headline, url} = data.data;
  const [image, setImage] = useState("");

  (function (filePath) {
    import(
      `../img${filePath}`
      ).then((image) => setImage(image.default));
  })(hero);

  return (
    <>
      { image &&
        <Hero hero={image} headline={headline} link={url}/>
      }
    </>
  );
};

const Travel = () => {
  return (
    <main className="travels">
      <Hero hero={hero} headline={'Places we have visited over the years'}/>
      {
        locationData.map((location, index) => {
          return <Location data={location} key={index} />
        })
      }
    </main>
  );
}

export default Travel;
