import React from 'react';
import DOMPurify from "dompurify";
import {Link} from "react-router-dom";


const Headline = ({ subheadline, headline }) => {
  return (
    <div className="hero-wrapper">
      <h2>{headline}</h2>
      <div dangerouslySetInnerHTML={ {__html: DOMPurify.sanitize(subheadline)} } />
    </div>
  )
}

const Hero = (props) => {
  const {hero, headline, link, subheadline} = props;
  return (
    <div className="hero">
      <img src={hero} alt="" width="1920" height="1080"  />
      {
        link &&
          <Link to={link}>
            <Headline headline={headline} subheadline={subheadline}/>
          </Link>
      }
      {
        !link && headline &&
        <Headline headline={headline} subheadline={subheadline}/>
      }
    </div>
  );
};

export default Hero;
