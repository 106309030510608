import React from "react";
import Hero from "../components/Hero";
import hero from "../img/food.webp";

const Food = () => {
  return (
    <main className="food">
      <Hero hero={hero} headline={`Our Favorite Recipes and Restaurants`}/>
    </main>
  );
}

export default Food;
