import React, {useRef, useState, useEffect} from "react";
import FadeInSection from "../FadeInSection";

const Slider = (props) => {
  const children = props.children;
  const [style, setStyle] = useState(0);

  const handleScroll = () => {
    setStyle(style + 100);
  }
  return (

    <div className="slider" onScroll={handleScroll}>
      <div className="inner-wrapper" style={{transform: [{translateX: -style + 'vw'}]}} >
        {
          children.length > 1 && children.map((child, index) => {
            return (
              <FadeInSection key={index}>
                <section className="slide">
                  {child}
                </section>
              </FadeInSection>
            )
          })
        }
        {
          !children.length && children &&
          <FadeInSection>
            <section className="slide">
              {children}
            </section>
          </FadeInSection>
        }
      </div>
    </div>
  );
}

export default Slider;