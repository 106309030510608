import Header from "./components/Header";
import Footer from "./components/Footer";
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Gallery from "./pages/gallery";
import Home from "./pages/home";
import Travel from "./pages/travel";
import Food from "./pages/food";
import TravelLocation from "./pages/travelLocation";

function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route exact path='/' element={<Home />} />
        <Route path='/gallery' element={<Gallery />} />
        <Route path='/travel' element={<Travel />} />
        <Route path='/food' element={<Food />} />
        <Route path="/travel/:id" element={<TravelLocation />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
