import React from 'react';
import hero from '../../img/main-hero.webp';

const setClasses = () => {
  // set classes
  const classes     = new Array ('pink', 'blue', 'green');
  const length      = classes.length;
  return ( classes[ Math.floor ( Math.random() * length ) ] );
}

const MasonryGrid = (props) => {
  const children = props.children;
  return (
    <div className="masonry">
      {
        children && children.map((child, index) => {
          return (
            <div key={index} className="grid">
              <img src={child} alt="" />
            </div>
          )
        })
      }
    </div>
  );
};

export default MasonryGrid;
