import React, {useState} from "react";
import DOMPurify from "dompurify";
import { useParams } from "react-router-dom";
import locationData from "../data/travel.json";
import Hero from "../components/Hero";

DOMPurify.addHook('afterSanitizeAttributes', function (node) {
  // set all elements owning target to target=_blank
  if ('target' in node) {
    node.setAttribute('target', '_blank');
    node.setAttribute('rel', 'noopener');
  }
});

const Location = (item) => {
  const {copy, hero} = item.data;
  const [image, setImage] = useState("");

  (function (filePath) {
    import(
      `../img${filePath}`
      ).then((image) => setImage(image.default));
  })(hero);

  return (
    <>
      { image &&
        <section className="location">
          <img className="" src={image} alt={"Test"} />
          <div dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(copy)
          }} />
        </section>
      }
    </>
  );
};

const TravelLocation = (props) => {
  const { id } = useParams();
  const location = locationData.filter(obj => {
    return obj.id === id;
  })[0];

  const [image, setImage] = useState("");

  (function (filePath) {
    import(
      `../img${filePath}`
      ).then((image) => setImage(image.default));
  })(location.hero);

  return (
    <main className="travels">
      {
        image &&
        <Hero hero={image} headline={location.headline} subheadline={location.subheadline}/>
      }
      {
        location.days.map((day, index) => {
          return <Location data={day} key={index} />
        })
      }
    </main>
  );
}

export default TravelLocation;
