import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faHouse, faImages, faUtensils, faPlaneDeparture} from '@fortawesome/free-solid-svg-icons'

const homeIcon = <FontAwesomeIcon icon={faHouse} />
const galleryIcon = <FontAwesomeIcon icon={faImages} />
const recipesIcon = <FontAwesomeIcon icon={faUtensils} />
const travelIcon = <FontAwesomeIcon icon={faPlaneDeparture} />

const Nav = () => {
  return (
    <nav className="navigation">
      <ul>
        <li>
          <a href="/">{homeIcon}<span className="sr-only">Home</span></a>
        </li>
        <li>
          <a href="/gallery">{galleryIcon}<span className="sr-only">Gallery</span></a>
        </li>
        <li>
          <a href="/food">{recipesIcon}<span className="sr-only">Food</span></a>
        </li>
        <li>
          <a href="/travel">{travelIcon}<span className="sr-only">Travel</span></a>
        </li>
      </ul>
    </nav>
  );
};

export default Nav;
