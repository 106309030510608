import React from "react";
import Hero from "../components/Hero";
import hero from "../img/main-hero.webp";
import MasonryGrid from "../components/MasonryGrid";

const importAll = (r) => {
  let images = {};
  images = r.keys().map((item, index) => {
    return r(item);
  });
  return images;
}

const Gallery = () => {
  const images = importAll(require.context('../img/homeGallery', false, /\.webp$/));

  return (
    <main className="gallery">
      <MasonryGrid>
        {images}
      </MasonryGrid>
    </main>
  );
}

export default Gallery;
