import React from "react";
import Slider from '../components/Slider';
import Hero from "../components/Hero";
import hero from "../img/main-hero.webp";
import ptc from "../img/homeGallery/IMG_5150.webp";
import azalea from "../img/homeGallery/azalea_1440.webp";

const Home = () => {
  return (
    <main className="home">
      <Slider>
        <div className="d-flex align-items-center justify-content-center p-3 flex-column w-100 h-100">
          <h2>A little piece of <i className="text-tertiary">serenity</i> in the <i>middle</i> of <i>chaos</i></h2>
          <small className="instruction">Swipe to navigate</small>
          <small className="desktop-instruction">Scroll to navigate</small>
        </div>
        <Hero hero={hero} />
        <section className="slide align-items-center justify-content-center p-3 flex-column w-100 h-100 d-flex">
          <h2>In the young city of <i className="text-tertiary">Peachtree Corners</i></h2>
        </section>
        <Hero hero={azalea} />
        <section className="slide align-items-center justify-content-center p-3 flex-column w-100 h-100 d-flex">
          <h2>Here lies the <i className="text-tertiary">Harmony House</i></h2>
        </section>
        <Hero hero={ptc} />
      </Slider>
    </main>
  );
}

export default Home;
